'use strict'

import asyncAppend from "../libs/@elements/async-append";
import elementsFetch from "../libs/@elements/fetch";
import {getLoadingContainer, removeLoadingContainer} from "./loading";
import formDataEntries from 'form-data-entries';

let disabledUnavailableModal = false;

function showUnavailableModal($form) {
    if (!disabledUnavailableModal) {
        $("body").removeClass("modal-open");
        $form.find(".js-cart-unavailable-modal").modal("show");

        $form.find(".js-cart-unavailable-modal").on("hidden.bs.modal", function () {
            $form.find(".js-cart-unavailable-modal").remove();
        });
    } else {
        // remove it from the dom, otherwise the duplicate inputs from the modal override the page inputs
        $form.find(".js-cart-unavailable-modal").remove();
        $("body").removeClass("modal-open");
    }
}

export function initInScope($scope) {
    const $form = $scope.find('.js-cart-detail__form');
    const $modalForm = $scope.find('.js-cart-detail_select_multiple__form');

    $form.on("change", "input:not([type=\"checkbox\"])", function () {
        changeForm($form);
    });

    $form.on("cart-update", function () {
        const payload = [["action", "cartUpdate"]];

        changeForm($form, payload);
    });

    $form.on("click", ".js-cart-detail__delete", function () {
        const payload = [["action", "delete"], ["articleId", $(this).data("article-id")], ["cartId", $(this).data("cart-id")]];

        changeForm($form, payload)
        .then(() => {
            return fetch('/a/cart/cart-price-info-json');
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            const prodId = $(this).data("prodnumber");
            _paq.push(['removeEcommerceItem', prodId]);
            const totalValue = data.grandTotal;
            _paq.push(['trackEcommerceCartUpdate', totalValue]);
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    });

    $form.on("click", ".js-cancel-unavailable-products", function () {
        disabledUnavailableModal = true;
    });

    $form.on("click", ".js-remove-unavailable-products", function (e) {
        const payload = [["action", "remove-unavailable"], ['cartId', $(this).data('cart-id')]];

        changeForm($form, payload);
    });
    $form.on("submit", function (e) {
        alert('s')
        e.preventDefault();
        return false;
    });
    showUnavailableModal($form);
}

function changeForm($form, additionalPayload) {
    return new Promise((resolve, reject) => {
        let payload = formDataEntries($form.get(0));

        if (additionalPayload) {
            additionalPayload.forEach((item) => {
                payload.push(item);
            });
        }

        asyncAppend({
            $target: $form,
            $loading: $($($(getLoadingContainer()).appendTo($form)), $($(getLoadingContainer()).appendTo($form.find(".js-cart-unavailable-modal .modal-content"))))
        }, elementsFetch($form.attr("action"), {
            body: new URLSearchParams(payload)
        })).finally(() => {
            showUnavailableModal($form);
            removeLoadingContainer($form);
            resolve(); // Resolve the promise after form update
        }).catch(error => {
            reject(error); // Reject the promise if there's an error
        });
    });
}