import $, { parseJSON } from 'jquery';

// import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules = app.modules || {};

import initModulesInScope from "./libs/@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('./libs/@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/
import * as Macy from 'macy';

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as goToLink from './libs/@elements/go-to-link';
app.goToLink = goToLink.init;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as toc from './libs/@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from './libs/@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as productGridFilter from './scripts/productGridFilter';
app.modules.productGridFilter = productGridFilter.initInScope;

import * as lightbox from './libs/@elements/lightbox';
app.modules.lightbox = lightbox.createInitInScope({
    base: '.js-lightbox',
    item: '.js-lightbox__item'
}, {
    hideBarsDelay: 99999999,
    subHtmlSelectorRelative: true
});

import * as datepicker from './libs/@elements/datepicker';
// app.modules.datepicker = datepicker.initInScope;
app.modules.datepicker = datepicker.createInitInScope({
    base: '.js-datepicker',
    input: '.js-datepicker__input',
    altField: '.js-datepicker__alt-field'
}, {
    /*options*/
    numberOfMonths: 1,
    nextText: '<span class="icon icon-arrow-left"></span>',
    prevText: '<span class="icon icon-arrow-left"></span>',
});

import * as floatingLabel from './libs/@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as pdsVideo from './scripts/pds-video';
app.modules.pdsVideo = pdsVideo.initInScope;

import * as header from './scripts/header';
app.header = header.init;

import * as parsley from './libs/@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as navbarToggle from './scripts/navbar-toggle';
app.modules.navbarToggle = navbarToggle.initInScope;

import * as matchMediaViewer from "./scripts/matchmedia-viewer";
app.matchMediaViewer = matchMediaViewer.init;
app.modules.matchMediaViewer = matchMediaViewer.initInScope;

import * as keepOpenDropdown from './scripts/keep-open-dropdown';
app.modules.keepOpenDropdown = keepOpenDropdown.initInScope;

import * as formAutoSubmit from './scripts/form-auto-submit';
app.modules.formAutoSubmit = formAutoSubmit.initInScope;

import * as overlayToggle from './scripts/overlay-toggle';
app.modules.overlayToggle = overlayToggle.initInScope;

import * as productLightbox from './scripts/product-lightbox';
app.modules.productLightbox = productLightbox.initInScope;

import * as touchNav from './scripts/touch-nav';
app.modules.touchNav = touchNav.initInScope;

import * as navAccesibility from './libs/@elements/accessibility-nav';
app.modules.accessibilityNav = navAccesibility.initInScope;

import * as productTeaserMainSlider from './scripts/product-teaser-main-slider';
app.modules.productTeaserMainSlider = productTeaserMainSlider.initInScope;

import * as productGrid from './libs/@elements/product-grid';
app.productGrid = productGrid.init;
app.modules.productGrid = productGrid.initInScope;

import * as productGridQuickFilter from './libs/@elements/product-grid/quick-filter';
app.modules.productGridQuickFilter = productGridQuickFilter.initInScope;

import * as submitForm from './scripts/submitForm';
app.modules.submitForm = submitForm.initInScope;

import * as cartOverview from './scripts/cartOverview';
app.modules.cartOverview = cartOverview.initInScope;

import * as cart from './scripts/cart';
app.cart = cart.init;
app.modules.cart = cart.initInScope;

import * as teaserSlider from './scripts/teaser-slider';
app.modules.teaserSlider = teaserSlider.initInScope;

import * as wysiwygSlider from './scripts/wysiwyg-slider';
app.modules.wysiwygSlider = wysiwygSlider.initInScope;

import * as addCart from './scripts/addCart';
app.addCart = addCart.init;
app.modules.addCart = addCart.initInScope;

import * as cartDetail from './scripts/cartDetail';
app.modules.cartDetail = cartDetail.initInScope;

import * as ImgTeaserSlider from './scripts/img-teaser-slider';
app.modules.ImgTeaserSlider = ImgTeaserSlider.initInScope;

import * as CustomImgSlider from './scripts/custom-img-slider';
app.modules.CustomImgSlider = CustomImgSlider.initInScope;

import * as gallerySlider from './scripts/gallery-slider';
app.modules.gallerySlider = gallerySlider.initInScope;

import * as multipleFileDownload from './scripts/multiple-file-download';
app.modules.multipleFileDownload = multipleFileDownload.initInScope;

import * as affix from './libs/@elements/affix';
app.modules.affix = affix.createInitInScope({
    mediaQuery: '(min-width: 768px)'
});

import * as ejb from './libs/@elements/elements-job-bundle';
app.modules.ejbAjaxForm = ejb.ajaxForm.initInScope;
app.modules.ejbDateInput = ejb.dateInput.initInScope;
app.modules.ejbGallery = ejb.gallery.initInScope;

import * as smoothScroll from './libs/@elements/smooth-scroll';
app.smoothScroll = smoothScroll.init;
app.modules.smoothScroll = smoothScroll.initInScope;

import * as anchorTabs from './scripts/anchor-tabs';
app.modules.anchorTabs = anchorTabs.initInScope;

import * as storeSearch from './scripts/storeSearch';
app.modules.storeSearch = storeSearch.initInScope;

import * as checkout from './scripts/checkout';
app.modules.checkout = checkout.initInScope;

import * as scrollTop from './scripts/scrollTop';
app.modules.scrollTop = scrollTop.initInScope;

import * as wishlist from './scripts/wishlist';
app.wishlist = wishlist.init;
app.modules.wishlist = wishlist.initInScope;

import * as scrollTo from './scripts/scroll-to';
app.modules.scrollTo = scrollTo.initInScope;

import * as centerActiveTab from './scripts/center-active-tab';
app.modules.centerActiveTab = centerActiveTab.initInScope;

import * as checkAvailability from './scripts/check-availability';
app.modules.checkAvailability = checkAvailability.initInScope;

import * as toggleClick from './scripts/toggle-click';
app.modules.toggleClick = toggleClick.initInScope;

import * as tooltip from './scripts/tooltip';
app.modules.tooltip = tooltip.initInScope;

import * as repeater from './scripts/repeater';
app.modules.repeater = repeater.initInScope;

import * as ajaxLoad from './scripts/ajaxLoad';
app.modules.ajaxLoad = ajaxLoad.initInScope;

import * as requestCart from "./scripts/requestCart";
app.modules.requestCart = requestCart.initInScope;

import * as waitForPayment from "./scripts/waitForPayment";
app.modules.waitForPayment = waitForPayment.initInScope;

// import * as targeting from "./scripts/targeting";
// app.targeting = targeting.initInScope;


import * as popover from './scripts/popover';
app.modules.popover = popover.initInScope;

import * as copyToClipboard from './scripts/copyToClipboard';
app.modules.copyToClipboard = copyToClipboard.initInScope;

import * as cutoutSlider from './scripts/cutout-slider';
app.modules.cutoutSlider = cutoutSlider.initInScope;

import * as tracking from "./libs/@elements/tracking";
tracking.initGtmEventTracking();

import * as slider from './libs/@elements/slider';
app.modules.productDetailSlider = slider.createInitInScope('.js-product-detail-slider', {
    nextArrow: '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-long-right"></span></button>',
    prevArrow: '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-long-left"></span></button>',
    infinite: false,
    slidesToShow: 1.3,
    slidesToScroll: 1
});

import * as circleImgTeaserSlider from './scripts/circle-img-teaser-slider';
app.modules.circleImgTeaserSlider = circleImgTeaserSlider.initInScope;

app.modules.simpleImgSlider = slider.createInitInScope('.js-simple-img-slider', {
    nextArrow: '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-long-right"></span></button>',
    prevArrow: '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-long-left"></span></button>',
    infinite: false,
    slidesToShow: 1.3,
    slidesToScroll: 1
});


app.modules.storeSlider = slider.createInitInScope('.js-store-slider', {
    nextArrow: '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-long-right"></span></button>',
    prevArrow: '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-long-left"></span></button>',
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1
});


import * as ajaxForm from './libs/@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.createInitInScope({
    addUrlParams: true
});

import * as ajaxFormScroll from './scripts/ajax-form-scroll';
app.modules.ajaxFormScroll = ajaxFormScroll.initInScope;

import * as hotspots from './scripts/hotspots';
app.hotspots = hotspots.init;
app.modules.hotspots = hotspots.initInScope;

import * as reCaptchaV3 from './scripts/reCaptchaV3';
app.modules.reCaptchaV3 = reCaptchaV3.initInScope;

// import { showCookieBar } from "./libs/@elements/cookie-permissions";
// if (!_config.admin && !_config.editmode && _config.showCookieBar) {
//     showCookieBar();
// }

import { translate } from "./libs/@elements/translations";


import * as typeahead from './libs/@elements/typeahead';
let typeaheadOptions = {
    autocompleteText: 'title'
};

const layouts = {
    headerLayout: (data, category) => {
        return ('<div class="typeahead-heading d-flex justify-content-between">' +
            '<a href="' + data.overviewUrl + '" class="typeahead-heading__text mb0">' +
            '<span class="typeahead-heading__icon text-primary ' + category.icon + '"></span><span>' + category.title + '</span></a>' +
            '<a href="' + data.overviewUrl + '" class="typeahead-heading__link mb0">' +
            '<span class="">' + translate('typeahead.showmore') + '</span></a>' +
            '</div>')
    },
    suggestionLayout: (data) => {
        return (
            '<div class="typeahead-suggestion">' + data.title + '<div class="typeahead-suggestion__text">' + data.text + '</div></div>')
    },
    errorLayout: () => {
        return ''
    }
};

app.modules.typeahead = typeahead.createInitInScope(typeaheadOptions, layouts);

import * as fileInput from './libs/@elements/file-input';
app.modules.fileInput = fileInput.initInScope;

import * as quickOrder from './scripts/quick-order';
app.modules.quickOrder = quickOrder.initInScope;

import * as langLinks from './scripts/lang-links';
app.modules.langLinks = langLinks.initInScope;

import * as variantSelect from './scripts/variant-select';
app.modules.variantSelect = variantSelect.initInScope;

import * as filterSearch from './scripts/filter-search';
app.modules.filterSearch = filterSearch.initInScope;

import * as perPage from './scripts/per-page';
app.modules.perPage = perPage.initInScope;

import * as createCart from './scripts/create-cart';
app.modules.createCart = createCart.initInScope;

if (matchMedia('(max-width: 767px)').matches) {
    let $slider = $('.js-tab-slider-xs');
    app.modules.tabSliderXs = slider.createInitInScope($slider, {
        nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-down"></span></button>',
        prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-down"></span></button>',
        infinite: false,
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: true
    });

    $slider.find('a[data-toggle="tab"]').on("show.bs.tab", function (evt) {
        $slider.find('a[data-toggle="tab"]').filter((index, item) => {
            if (item !== evt.target) {
                $(item).removeClass('active');
                $(item.getAttribute('href')).removeClass('show')
            }
        });
    });
}

import * as console from './scripts/console';
app.modules.console = console.initInScope;

import * as steps from './scripts/steps';
app.modules.steps = steps.initInScope;

import * as fadeIn from './scripts/fade-in';
app.modules.fadeIn = fadeIn.initInScope;

app.modules.quoteSlider = slider.createInitInScope('.js-quote-slider', {
    nextArrow: '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-long-right"></span></button>',
    prevArrow: '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-long-left"></span></button>',
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1
});

app.modules.mediaBoxSlider = slider.createInitInScope('.js-media-box-slider', {
    nextArrow: '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-long-right"></span></button>',
    prevArrow: '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-long-left"></span></button>',
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1
});

import * as viewportVideo from './scripts/viewport-video';
app.modules.viewportVideo = viewportVideo.initInScope;

(function ($) {
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each(_config['_preload'], function (_key, _val) {
            if (typeof _val == 'boolean' && typeof window.app[_key] == 'function') {
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each(_config, function (_key, _val) {
        if ((typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload')) {
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each(_config['_reload'], function (_key, _val) {
            if ((typeof _val == 'boolean' && typeof window.app[_key] == 'function')) {
                window.app[_key]();
            }
        });
    }

    $("body").on("click", ".rating-item", function () {
        $(".rating-active").removeClass("rating-active");
        $(this).addClass('rating-active');
        $(this).parent().parent().find('input').val($(this).find('.rating-number').text().trim());
    })
    function addAndRecalculateHotspots() {
        var images = $(".project-gallery-grid__img-wrapper");
        var hotspots = $(".hotspot");
        var $template = $(".hotspot__template");
        if ($template.length) {
            $template = $template[0].innerHTML;

            for (let i = hotspots.length; i < images.length / 8; i++) {
                var offset = 0;
                if (i % 2 === 0) {
                    offset = 0;
                } else {
                    offset = 3;
                }
                $(images[i * 8 + offset]).append($template);
            }
            hotspots = $(".hotspot");
            for (let i = 0; i < hotspots.length; i++) {
                if (i % 2 === 0) {
                    $(hotspots[i]).css('left', '-50px');
                } else {
                    $(hotspots[i]).css('float', 'right');
                    $(hotspots[i]).css('right', '-50px');
                    $(hotspots[i]).css('left', 'auto');
                }
                if (i % 4 === 0) {
                    $(hotspots[i]).css('height', '600px');
                } else if (i % 4 === 1) {
                    $(hotspots[i]).css('height', '300px');
                } else if (i % 4 === 2) {
                    $(hotspots[i]).css('height', '300px');
                } else if (i % 4 === 3) {
                    $(hotspots[i]).css('height', '600px');
                }
            }
        }
    }

    function getUrlFromInput(keyword) {
        var url = "";
        $(".js-product-grid__input:checked").each(function () {
            if (keyword === undefined || $(this)[0].value.toLowerCase() !== keyword) {
                if (url === "") {
                    url = location.href.split("?")[0] + "?";
                } else {
                    url += "&"
                }
                url += $(this)[0].name + "[]=" + $(this)[0].value;
            }
        })
        if (url === "") {
            url = location.href.split("?")[0];
        }
        return url
    }
    if ($(".project-gallery-grid__gallery-wrapper").length) {
        var macy = Macy({
            container: '.project-gallery-grid__gallery-wrapper',
            trueOrder: false,
            waitForImages: false,
            margin: 12,
            columns: 4,
            breakAt: {
                1200: 4,
                940: 3,
                520: 2,
                400: 1
            }
        });
        macy.runOnImageLoad(function () {
            macy.recalculate(true, true);
            $(".project-gallery-grid__img-overlay").removeClass("not-loaded-yet");
        });
        addAndRecalculateHotspots();
    }
    const $body = $("body");
    $body.on("submit", ".js-request-loxone__form", function (e) {
        e.preventDefault();
        let data = $(".js-request-loxone__form").serialize();
        $.ajax({
            url: '/request-loxone', 
            method: 'POST', 
            data: data, 
            success: (data) => {
                $(".modal-body__close").click();
                $(".js-request-loxone__form")[0].reset();

            }, error: (data) => { }
        })
    });
    $body.on("click", ".project-gallery-grid__apply-filter--js", function () {
        $(".js-product-grid__loading")[0].hidden = false;
        const url = getUrlFromInput();

        history.pushState({
            'productGridAjaxUrl': url,
            'productGridInputState': [],
            'productGridAdditionalInputState': []
        },
            document.title,
            url
        );
        $.ajax({
            url: url,
            dataType: "json",
            success: (data) => {
                $(".js-product-grid__loading")[0].hidden = true;
                $(".pagenumber")[0].innerText = 0;
                const filterBar = $(".filter-bar__container")
                filterBar.empty();
                filterBar.append(data.filter);
                const galleryWrapper = $(".project-gallery-grid__gallery-wrapper")
                galleryWrapper.empty();
                galleryWrapper.append(data.html);
                macy.runOnImageLoad(function () {
                    macy.recalculate(true, true);
                    $(".project-gallery-grid__img-overlay").removeClass("not-loaded-yet");
                });
                addAndRecalculateHotspots();
                productLightbox.initInScope($body);
                filterSearch.initInScope($body);
                overlayToggle.initInScope($body);
            }
        });
    });
    $body.on("click", ".js-project-gallery-grid-quick-filter__item", function () {
        $(".js-product-grid__loading")[0].hidden = false;

        var keyword = $(this).find(".js-project-gallery-grid-quick-filter__text")[0].innerText;
        keyword = keyword.toLowerCase();
        const url = getUrlFromInput(keyword);

        history.pushState({
            'productGridAjaxUrl': url,
            'productGridInputState': [],
            'productGridAdditionalInputState': []
        },
            document.title,
            url
        );
        $.ajax({
            url: url,
            dataType: "json",
            success: (data) => {
                $(".js-product-grid__loading")[0].hidden = true;
                $(".pagenumber")[0].innerText = 0;
                const filterBar = $(".filter-bar__container")
                filterBar.empty();
                filterBar.append(data.filter);
                const galleryWrapper = $(".project-gallery-grid__gallery-wrapper")
                galleryWrapper.empty();
                galleryWrapper.append(data.html);
                macy.runOnImageLoad(function () {
                    macy.recalculate(true, true);
                    $(".project-gallery-grid__img-overlay").removeClass("not-loaded-yet")
                });
                addAndRecalculateHotspots();
                productLightbox.initInScope($body);
                filterSearch.initInScope($body);
                overlayToggle.initInScope($body);
            }
        });
    });
    $body.on("click", ".js-project-gallery-grid__remove-input", function () {
        $(".js-product-grid__loading")[0].hidden = false;
        const url = location.href.split("?")[0];
        history.pushState({
            'productGridAjaxUrl': url,
            'productGridInputState': [],
            'productGridAdditionalInputState': []
        },
            document.title,
            url
        );
        $.ajax({
            url: url,
            dataType: "json",
            success: (data) => {
                $(".js-product-grid__loading")[0].hidden = true;
                $(".pagenumber")[0].innerText = 0;
                const filterBar = $(".filter-bar__container")
                filterBar.empty();
                filterBar.append(data.filter);
                const galleryWrapper = $(".project-gallery-grid__gallery-wrapper")
                galleryWrapper.empty();
                galleryWrapper.append(data.html);
                macy.runOnImageLoad(function () {
                    macy.recalculate(true, true);
                    $(".project-gallery-grid__img-overlay").removeClass("not-loaded-yet")
                });
                addAndRecalculateHotspots();
                productLightbox.initInScope($body);
                filterSearch.initInScope($body);
                overlayToggle.initInScope($body);
            }
        });
    });
    $body.on("click", ".js-keep-open-dropdown-menu", function (event) {
        event.stopPropagation();
    });
    var processing = false
    $(document).scroll(function (e) {
        // grab the scroll amount and the window height
        var scrollAmount = $(window).scrollTop();
        var documentHeight = $(document).height();
        var windowHeight = $(window).height();
        var offset = 1200;

        if (scrollAmount >= documentHeight - windowHeight - offset && !processing) {
            load_more();
        }

        function load_more() {
            processing = true;
            const pageNumber = $(".pagenumber");
            if (pageNumber) {
                pageNumber[0].innerText = parseInt(pageNumber[0].innerText) + 1;
                $.ajax({
                    url: location.href,
                    type: "POST",
                    dataType: "json",
                    data: {
                        "load_more": true,
                        "page": pageNumber[0].innerText
                    },
                    success: (data) => {
                        processing=false;
                        $(".project-gallery-grid__gallery-wrapper").append(data.html);
                        macy.runOnImageLoad(function (){
                            macy.recalculate(true, true);
                            $(".project-gallery-grid__img-overlay").removeClass("not-loaded-yet")
                        });
                        productLightbox.initInScope($body);
                        addAndRecalculateHotspots();
                    },
                    error: (data) => {
                        processing=false;
                    }
                });
            }
        }

    });

    $body.on("click", ".js-multiple-file-download__selected", function () {
        window.dataLayer = window.dataLayer || [];
        $(".js-multiple-file-download-select-container").find('.custom-checkbox__input:checked').each(function(){
            let name = $(this).attr('data-original-label');
            let filepath = $(this).attr('data-filepath');
            let filetype = filepath.slice(-3);
            window.dataLayer.push({
                event: 'downloadFile',
                'file_path': filepath,
                'file_type': filetype,
                'file_name': name
            });
        });
    });

    let oldName = $('.js_colorName').html();
    // let oldImage = $('.js_colorName').html()
    $('body .product-detail-main .js_colorPicker').on("mouseenter", function () {
        let newColor = $(this).find('input').val();
        let newColorName = $(this).find('img').attr('title');
        let newImage = $(document).find('.js_colorImage[data-colorimage="' + newColor + '"]')
        $(newImage).removeClass('hidden_for_others').addClass('show-color-image');
        $('.js_originalImage').addClass('hide_opacity');
        $('.js_colorName').html(newColorName);

    });
    $('body .product-detail-main .js_colorPicker').on("mouseleave", function () {
        $('.js_colorName').html(oldName);
        $('.js_colorImage').removeClass('show-color-image').addClass('hidden_for_others');
        $('.js_originalImage').removeClass('hide_opacity');
    });
    initModulesInScope();


    // Page Load Fix --> for more infos check out base.css and the loaded class
    $(document).ready(function () {
        $('body').addClass('loaded');
    });

    window.addEventListener("load", (event) => {
        if (window.location.href.includes('#') && window.scrollY == 0) {
            let href = window.location.href;
            let id = href.split(/[#?]/)[1];
            setTimeout(function () {
                document.querySelector('#' + id).scrollIntoView();
            }, 100);
        }
    });
})($);


