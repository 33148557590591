"use strict";

import $ from 'jquery'
import 'slick-carousel';
import {loadImg} from "../libs/@elements/lazy-img";
import {onEnterViewPort} from "../libs/@elements/viewport-utils";


export function initInScope($scope) {

    let $slider = $scope.find('.js-wysiwyg-slider');

    $slider.each(function () {
        let $slider = $(this),
            slidesToShow = 4,
            slidesToShowMobile = 1,
            arrowPrevMarkup = '<button type="button" class="slick-prev slider__arrow" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
            arrowNextMarkup = '<button type="button" class="slick-next slider__arrow" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>';

        if($slider.data('slides-to-show')){
            slidesToShow = $slider.data('slides-to-show');
        }

        if($slider.data('slides-to-show-mobile')){
            slidesToShowMobile = $slider.data('slides-to-show-mobile');
        }

        if($slider.data('styled-arrows')){
            arrowPrevMarkup = '<button type="button" class="slick-prev slider__arrow-styled" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-long-left"></span></button>';
            arrowNextMarkup = '<button type="button" class="slick-next slider__arrow-styled" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-long-right"></span></button>';
        }

        let sliderOptions = {
            nextArrow: $('.wysiwyg-next-arrow'),
            prevArrow: $('.wysiwyg-prev-arrow'),
            infinite: true,
            rows: 0,
            slidesToShow: slidesToShow,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: slidesToShowMobile
                    }
                }
            ]
        };

        const $tabPaneParent = $slider.closest('.tab-pane:not(.active)');
        const $collapseParent = $slider.closest('.collapse');
        const $modalParent = $slider.closest('.modal');

        if ($tabPaneParent && $tabPaneParent.length) {
            /*wait for the initializiation of the slider if it is hidden in a tab */
            const $tabTrigger = $('[href="#' + $tabPaneParent.attr('id') + '"],[data-toggle=tab][data-target="#' + $tabPaneParent.attr('id') + '"]');

            $tabTrigger.one('shown.bs.tab', function () {
                initSlider($slider, sliderOptions);
            });

        } else if ($collapseParent && $collapseParent.length) {
            /* wait until slide is shown if it is initially hidden in a collapse/accordion */
            $collapseParent.one('shown.bs.collapse', function () {
                initSlider($slider, sliderOptions);
            });

        } else if ($modalParent && $modalParent.length) {
            /* wait until slide is shown if it is initially  hidden in a modal */
            $modalParent.one('shown.bs.modal', function () {
                initSlider($slider, sliderOptions);
            });

            if($modalParent.hasClass("show")) {
                initSlider($slider, sliderOptions);
            }

        } else {
            // no tab, accordion, modal -> init slider as usual
            initSlider($slider, sliderOptions);
        }
    });

}

function initSlider($slider, sliderOptions) {
    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + n);
    }
}
